/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from  '@material-ui/core/styles';
import { List, ListItem, Button, colors, Collapse, Link, Badge } from '@material-ui/core';
import { Home as HomeIcon,
ExpandLess,
ExpandMore,
PowerSettingsNew as LogoutIcon,
Drafts as LeaveIcon, AlarmOn as AttendanceIcon,
ArrowRight as MenuClose,
ArrowDropDown as MenuOpen,
AttachMoney,
LocalShipping,
AccountCircle as ProfileIcon,
Assignment as ReportIcon,
Settings as SettingsIcon,
Dashboard, Redeem, GetApp,
DoneAll,BorderAll, PeopleAlt,VerifiedUser,
ContactPhone, AddShoppingCart, AccountTree, Storefront as StorefrontIcon,
BusinessCenter as BusinessCenterIcon,
EventNote as EventNoteIcon, AccountBalance as AccountBalanceIcon,
ListAlt as ListAltIcon,ShoppingCart as ShoppingCartIcon,
Assignment as AssignmentIcon} from '@material-ui/icons';
import DataServices from '../../../../../../service/data-services';
import Configuration from '../../../../../../service/configuration';
import Resources from '../../../../../../service/resources';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next'
import { Inventory as InventoryIcon, PointOfSale as PointOfSaleIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  root: {margin:0,padding:0},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },  
  itemNested: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(4)
  },
  button: {
    color: colors.black,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
 itemNested2: {
   display: 'flex',
   paddingTop: 0,
   paddingBottom: 0,
   paddingLeft: theme.spacing(6)
 },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
  
    <RouterLink {...props} />
        
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

    const dataService = new DataServices( );
    const config = new Configuration();
    const resources = new Resources( );
    var auth = dataService.getAuthoritiesData( );
    var userData = dataService.getCurrentUserData();
    var posPrivilege = dataService.getCurrentUserPOSPrivilege();


    const classes = useStyles();

    var t = props.lang;
    var i18n = props.i18n;

    const [openDelivery, setOpenDelivery] = React.useState(window.location.pathname.includes('delivery'));
    const handleClickDelivery = () => {
      setOpenDelivery(!openDelivery);
    };
    const [openFinance, setOpenFinance] = React.useState(window.location.pathname.includes('finance'));
    const handleClickFinance = () => {
        setOpenFinance(!openFinance);
    };
    const [openUsers, setOpenUsers] = React.useState(window.location.pathname.includes('user'));
    const handleClickUsers = () => {
        setOpenUsers(!openUsers);
    };
    const [openZone, setOpenZone] = React.useState(window.location.pathname.includes('pricing'));
    const handleClickZone = () => {
        setOpenZone(!openZone);
    };
    const [openReports, setOpenReports] = React.useState(window.location.pathname.includes('report'));
    const handleClickReports = () => {
        setOpenReports(!openReports);
    };
    const [openSettings, setOpenSettings] = React.useState(window.location.pathname.includes('settings'));
    const handleClickSettings = () => {
        setOpenSettings(!openSettings);
    };
    const [openAddress, setOpenAddress] = React.useState(window.location.pathname.includes('address'));
    const handleClickAddress = () => {
        setOpenAddress(!openAddress);
    };
    const [openOrganization, setOpenOrganization] = React.useState(window.location.pathname.includes('org'));
    const handleClickOrganization = () => {
        setOpenOrganization(!openOrganization);
    };
    const [openPickup, setOpenPickup] = React.useState(window.location.pathname.includes('way'));
    const handleClickPickup = () => {
        setOpenPickup(!openPickup);
    };
    const [openMerchant, setOpenMerchant] = React.useState(window.location.pathname.includes('merchant'));
    const handleClickMerchant = () => {
        setOpenMerchant(!openMerchant);
    };
    const [openDeliveryman, setOpenDeliveryman] = React.useState(window.location.pathname.includes('deman'));
    const handleClickDeliveryman = () => {
        setOpenDeliveryman(!openDeliveryman);
    };
    const [openReporting, setOpenReporting] = React.useState(window.location.pathname.includes('report'));
    const handleClickReporting = () => {
        setOpenReporting(!openReporting);
    };
    const [openPos, setOpenPos] = React.useState(window.location.pathname.includes('pos'));
    const handleClickPos = () => {
      setOpenPos(!openPos);
    };
    const [openAccounting, setOpenAccounting] = React.useState(window.location.pathname.includes('accounting_'));
    const handleClickAccounting = () => {
      setOpenAccounting(!openAccounting);
    };
    const [openAccounts, setOpenAccounts] = React.useState(window.location.pathname.includes('accounts_'));
    const handleClickAccounts = () => {
      setOpenAccounts(!openAccounts);
    };
    const [openTransaction, setOpenTransaction] = React.useState(window.location.pathname.includes('transaction_'));
    const handleClickTransaction = () => {
      setOpenTransaction(!openTransaction);
    };
    const [openFinancial, setOpenFinancial] = React.useState(window.location.pathname.includes('financial_'));
    const handleClickFinancial = () => {
      setOpenFinancial(!openFinancial);
    };

    const [openPosOrder, setOpenPosOrder] = React.useState(window.location.pathname.includes('possale'));
    const handleClickPosSale = () => {
      setOpenPosOrder(!openPosOrder);
    };
    const [openPosPurchase, setOpenPosPurchase] = React.useState(window.location.pathname.includes('pospurchase'));
    const handleClickPosPurchase = () => {
      setOpenPosPurchase(!openPosPurchase);
    };

    const hrmLogin = () =>{
        dataService.retrieve( config.SERVICE_NAME_USER, config.SERVICE_ACTION_USER_HR_AUTHORIZATION).then(items => {
            if( items && items.success ) {
                var anchor = document.createElement('a');
                anchor.href = 'https://hrdemo.gigagates.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
//                anchor.href = 'https://ghr.gigagates.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
//                anchor.href = 'https://hrtest.quickdoor2door.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
                anchor.target="_blank";
                anchor.click();
            }else{
                alert(items.message);
            }
        });
    }

  return (
          <div>

            { auth.includes('MANAGER_READ') &&
                <List {...rest} className={clsx(classes.root, className)}>
                    <ListItem className={classes.item} disableGutters >
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={'/organization_admin_dashboard'}
                        >
                      <div className={classes.icon}>{<Dashboard />}</div>
                        {t('menu.dashboard')}
                      </Button>
                    </ListItem>
                </List>
            }

            { (userData && userData.roleName ==='Employee') &&
                <List {...rest} className={clsx(classes.root, className)}>
                    <ListItem className={classes.item} disableGutters >
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={'/deman_detail/' + userData.guid+'/'+userData.name+'/'+userData.personId+'/0'}
                        >
                      <div className={classes.icon}>{<BusinessCenterIcon />}</div>
                        {t('menu.dashboard')}
                      </Button>
                    </ListItem>
                </List>
            }

            { (userData && (userData.roleName ==='OS' ||userData.roleName ==='Customer') ) &&
                <List {...rest} className={clsx(classes.root, className)}>
                    <ListItem className={classes.item} disableGutters >
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={'/merchant_detail/' + userData.guid+'/'+userData.name+'/'+userData.personId+'/0'}
                        >
                      <div className={classes.icon}>{<BusinessCenterIcon />}</div>
                        {t('menu.dashboard')}
                      </Button>
                    </ListItem>
                </List>
            }

            { (auth && auth.includes('PICKUP_CREATE')) &&
                <List {...rest} className={clsx(classes.root, className)}>
                    <ListItem className={classes.item} disableGutters >
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={'/pickup_create'}
                        >
                      <div className={classes.icon}>{<BusinessCenterIcon />}</div>
                        {t('menu.create_delivery')}
                      </Button>
                    </ListItem>
                </List>
            }

          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPickup();}}>
                {openPickup ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<LocalShipping />}</div>
                  {t('menu.way_management')}
                </Button>
              </ListItem>

              <Collapse in={openPickup} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                      { auth.includes('PICKUP_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/way_pickup_list/All'} >
                              {t('menu.pickup_ways')}
                            </Button>
                          </ListItem>
                      }
                      { auth.includes('DELIVER_READ') &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/way_delivery_list/All'} >
                          {t('menu.deliver_ways')}
                        </Button>
                      </ListItem>
                      }
                      { auth.includes('USER_TRACKING_DELIVERYMAN') &&
                          <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/way_tracking'} >
                                {t('menu.tracking_map')}
                              </Button>
                          </ListItem>
                      }

                      { (auth.includes('STATION_INBOUND_LIST') && config.MODULE_WAREHOUSE_SERVICE) &&
                          <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/way_transit_inbound/All'} >
                                {t('menu.inbound_ways')}
                              </Button>
                          </ListItem>
                      }

                      { (auth.includes('STATION_OUTBOUND_LIST') && config.MODULE_WAREHOUSE_SERVICE) &&
                          <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/way_transit_outbound/All'} >
                                {t('menu.outbound_ways')}
                              </Button>
                          </ListItem>
                      }

                      { (auth.includes('EMPLOYEE_READ') && !auth.includes('MANAGER_READ') && config.MODULE_WAREHOUSE_SERVICE) &&
                          <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/way_transit_list/All'} >
                                {t('menu.station_in_out')}
                              </Button>
                          </ListItem>
                      }

                      { (auth.includes('STATION_OUTBOUND_LIST') && config.MODULE_WAREHOUSE_SERVICE) &&
                          <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/way_transit_bucket'} >
                                {'Buckets'}
                              </Button>
                          </ListItem>
                      }
                  </List>
              </Collapse>
          </List>

          { auth.includes('MANAGER_READ') &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickMerchant();}}>
                {openMerchant ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<StorefrontIcon />}</div>
                  {t('menu.merchants')}
                </Button>
              </ListItem>

              <Collapse in={openMerchant} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>

                      {auth.includes('USER_MERCHANT_ACCOUNT_CREATE') &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/merchant_create/OS/0'} >
                          {t('menu.add_new_merchant')}
                        </Button>
                      </ListItem>
                      }
                      {auth.includes('USER_ACCOUNT_MERCHANT_LIST') &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/merchant_list'} >
                          {t('menu.merchant_list')}
                        </Button>
                      </ListItem>
                      }
                      {auth.includes('FINANCE_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/merchant_receipt'} >
                              {t('menu.receipts')}
                            </Button>
                          </ListItem>
                      }

                      { auth.includes('ACCOUNTING_SIMPLE_TRANSACTION_CREATE') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/merchant_financial_center'} >
                              {'Financial center'}
                            </Button>
                          </ListItem>
                      }

                  </List>
              </Collapse>
          </List>
          }

          { auth.includes('MANAGER_READ') &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickDeliveryman();}}>
                {openDeliveryman ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<AssignmentIndIcon />}</div>
                  {t('menu.deliverymen')}
                </Button>
              </ListItem>

              <Collapse in={openDeliveryman} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                      { auth.includes('USER_DELIVERYMAN_ACCOUNT_CREATE') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/deman_create/Employee/0'} >
                              {t('menu.add_new_deliveryman')}
                            </Button>
                          </ListItem>
                      }
                      { auth.includes('USER_ACCOUNT_DELIVERYMAN_LIST') &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/deman_list'} >
                          {t('menu.deliveryman_list')}
                        </Button>
                      </ListItem>
                      }

                      { auth.includes('FINANCE_COD_FROM_DELIVERYMAN_LIST') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/deman_financial_center'} >
                              {'Financial center'}
                            </Button>
                          </ListItem>
                      }
                  </List>
              </Collapse>
          </List>
          }

        { auth.includes('FINANCE_READ') &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickAccounting();}}>
                {openAccounting ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<AccountBalanceIcon />}</div>
                  {t('menu.accounting')}
                </Button>
              </ListItem>

              <Collapse in={openAccounting} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>

                    <List {...rest} className={clsx(classes.root, className)}>
                      <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickAccounts();}}>
                        {openAccounts ? <MenuOpen /> : <MenuClose />}
                        <Button activeClassName={classes.active} className={classes.button} >
                          {t('menu.accounts')}
                        </Button>
                      </ListItem>
                      <Collapse in={openAccounts} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          { auth.includes('ACCOUNTING_ACCOUNT_BALANCE_READ') &&
                              <ListItem className={classes.itemNested2} disableGutters >
                                <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                to={'/accounting_accounts_balance'} >
                                  {t('menu.accounts_balance')}
                                </Button>
                              </ListItem>
                          }
                          { auth.includes('ACCOUNTING_ACCOUNT_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_accounts_title_list'} >
                              {t('menu.account_name_title')}
                            </Button>
                          </ListItem>
                          }
                        </List>
                      </Collapse>
                    </List>

                    <List {...rest} className={clsx(classes.root, className)}>
                      <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickTransaction();}}>
                        {openTransaction ? <MenuOpen /> : <MenuClose />}
                        <Button activeClassName={classes.active} className={classes.button} >
                          {t('menu.transactions')}
                        </Button>
                      </ListItem>
                      <Collapse in={openTransaction} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                          { auth.includes('ACCOUNTING_SIMPLE_TRANSACTION_CREATE') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_simple_journal_entry'} >
                              {t('menu.simple_transaction')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_CREATE') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_account_level_journal_entry/Non-Cash'} >
                              {t('menu.journal_voucher_entry')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_CREATE') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_account_level_cash_entry/Cash'} >
                              {'Cash voucher entry'}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_journal_voucher_list/Non-Cash'} >
                              {t('menu.general_journal_list')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_VOUCHER_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_cash_voucher_list'} >
                              {'Cash voucher list'}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_LEDGER_LIST') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_transaction_general_ledger_list'} >
                              {t('menu.general_ledger_list')}
                            </Button>
                          </ListItem>
                          }

                        </List>
                      </Collapse>
                    </List>

                    <List {...rest} className={clsx(classes.root, className)}>
                      <ListItem className={classes.itemNested} disableGutters onClick={event =>{handleClickFinancial();}}>
                        {openFinancial ? <MenuOpen /> : <MenuClose />}
                        <Button activeClassName={classes.active} className={classes.button} >
                          {t('menu.financial_reports')}
                        </Button>
                      </ListItem>
                      <Collapse in={openFinancial} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                          { auth.includes('ACCOUNTING_DAILY_ACCOUNTING_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_daily_accounting'} >
                              {t('menu.daily_accounting')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_JOURNAL_SUMMARY_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_journal_summary'} >
                              {t('menu.journal_summary')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_TRIAL_BALANCE_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_trial_balance'} >
                              {t('menu.trial_balance')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_INCOME_STATEMENT_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_income_statement_list'} >
                              {t('menu.income_statement')}
                            </Button>
                          </ListItem>
                          }
                          { auth.includes('ACCOUNTING_BALANCE_SHEET_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_balance_sheet'} >
                              {t('menu.balance_sheet')}
                            </Button>
                          </ListItem>
                          }

                          { auth.includes('ACCOUNTING_PROFIT_LOSS_READ') &&
                          <ListItem className={classes.itemNested2} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/accounting_financial_profit_and_loss'} >
                              {t('menu.profit_and_loss')}
                            </Button>
                          </ListItem>
                          }
                        </List>
                      </Collapse>
                    </List>
                  </List>
              </Collapse>
          </List>
        }
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickReporting();}}>
                {openReporting ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<ReportIcon />}</div>
                  {t('menu.reporting')}
                </Button>
              </ListItem>

              <Collapse in={openReporting} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                      { auth.includes('EMPLOYEE_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_completed_list'} >
                              {t('menu.ways_count_report')}
                            </Button>
                          </ListItem>
                      }

                      { auth.includes('EMPLOYEE_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_active_way_count_by_township'} >
                              {t('menu.active_ways_count_by_town')}
                            </Button>
                          </ListItem>
                      }

                      { auth.includes('MANAGER_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_to_deliver_deliveryman/deliveryman'} >
                              {t('menu.ways_for_deliverymen')}
                            </Button>
                          </ListItem>
                      }

                      { (userData && userData.roleName ==='Employee') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_to_deliver_deliveryman/deliveryman'} >
                              {t('menu.ways_to_deliver')}
                            </Button>
                          </ListItem>
                      }

                      { auth.includes('MANAGER_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_to_deliver_merchant/merchant'} >
                              {t('menu.ways_for_merchants')}
                            </Button>
                          </ListItem>
                      }

                      { (userData && userData.roleName ==='OS') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_to_deliver_merchant/merchant'} >
                              {t('menu.ways_to_deliver')}
                            </Button>
                          </ListItem>
                      }

                      { auth.includes('EMPLOYEE_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_overdue_count_by_date'} >
                              {t('menu.overdue_ways_count')}
                            </Button>
                          </ListItem>
                      }
                      { auth.includes('MANAGER_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_overdue_by_deliveryman'} >
                               {t('menu.overdue_ways_by_deliveryman')}
                            </Button>
                          </ListItem>
                      }

                      { auth.includes('MANAGER_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_overdue_by_merchant'} >
                              {t('menu.overdue_ways_by_merchant')}
                            </Button>
                          </ListItem>
                      }

                      { auth.includes('MANAGER_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_total_ways_by_town'} >
                              {'Total ways by town'}
                            </Button>
                          </ListItem>
                      }

                      { auth.includes('MANAGER_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_merchants_order_compare_by_month'} >
                              {'Merchants order compare'}
                            </Button>
                          </ListItem>
                      }

                      {/*{ auth.includes('FINANCE_READ') &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/report_income'} >
                          {t('menu.income_report')}
                        </Button>
                      </ListItem>
                      }
                      { auth.includes('FINANCE_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_expense'} >
                              {t('menu.expense_report')}
                            </Button>
                          </ListItem>
                      }
                      { auth.includes('FINANCE_READ') &&
                          <ListItem className={classes.itemNested} disableGutters >
                            <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                            to={'/report_gross_margin'} >
                              {t('menu.gross_summary')}
                            </Button>
                          </ListItem>
                      }*/}

                  </List>
              </Collapse>
          </List>


          { auth.includes('MANAGER_WRITE') &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters onClick={event =>{handleClickOrganization();}}>
                {openOrganization ? <MenuOpen /> : <MenuClose />}
                <Button activeClassName={classes.active} className={classes.button} >
                  <div className={classes.icon}>{<AccountTree />}</div>
                  {t('menu.organization')}
                </Button>
              </ListItem>

              <Collapse in={openOrganization} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>

                    { auth.includes('DEPARTMENT_CREATE') &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/org_branch/branch'} >
                          {t('menu.branches')}
                        </Button>
                      </ListItem>
                    }

                  { auth.includes('ORGANIZATION_WRITE') &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/org_facebook_linking'} >
                          {'Facebook connect'}
                        </Button>
                      </ListItem>
                  }

                    {auth.includes('USER_ACCOUNT_SYNC_TO_HRM') &&
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/org_sync_to_hrm'} >
                        {t('menu.sync_users_to_hrm')}
                      </Button>
                    </ListItem>
                    }

                  { auth.includes('ZONE_LIST') &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/org_delivery_zone'} >
                          {t('menu.zone_and_operation_area')}
                        </Button>
                      </ListItem>
                  }

                  { (auth.includes('ZONE_LIST') && config.MODULE_WAREHOUSE_SERVICE && config.MODULE_WAREHOUSE_SERVICE_SUGGEST_ROUTE) &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/org_zone_station_network'} >
                          {t('menu.station_network')}
                        </Button>
                      </ListItem>
                  }

                  { (auth.includes('ZONE_LIST') && config.MODULE_WAREHOUSE_SERVICE) &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/org_zone_station_map'} >
                          {t('menu.station_coverages')}
                        </Button>
                      </ListItem>
                  }

                  { (auth.includes('ORGANIZATION_WRITE') && config.MODULE_WAREHOUSE_SERVICE) &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/org_branch_finance'} >
                          {'Financial Center'}
                        </Button>
                      </ListItem>
                  }

                    {/*{ auth.includes('FINANCE_WRITE') &&
                      <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/org_expense'} >
                          {t('menu.expense')}
                        </Button>
                      </ListItem>
                     }*/}
                  </List>
              </Collapse>
          </List>
          }
          { ( auth && auth.includes('EMPLOYEE_READ') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      onClick={event =>{hrmLogin();}}
                  >
                <div className={classes.icon}>{<PeopleAlt />}</div>
                  {t('menu.hr_management')}
                </Button>
              </ListItem>
          </List>
          }

            { auth.includes('FINANCE_READ') &&
                <List {...rest} className={clsx(classes.root, className)}>
                    <ListItem className={classes.item} disableGutters onClick={event =>{handleClickZone();}}>
                    {openZone ? <MenuOpen /> : <MenuClose />}
                      <Button activeClassName={classes.active} className={classes.button} >
                        <div className={classes.icon}>{<Redeem />}</div>
                        {t('menu.pricing_and_package')}
                      </Button>
                    </ListItem>
                    <Collapse in={openZone} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            { ( auth && auth.includes('FINANCE_PRICING_LIST') ) &&
                            <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pricing_price_package'} >
                                {t('menu.price_package')}
                              </Button>
                            </ListItem>
                            }
                            { ( auth && auth.includes('FINANCE_PRICING_EXCLUSIVE_LIST') ) &&
                              <ListItem className={classes.itemNested} disableGutters >
                                <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                to={'/price_exclusive'} >
                                  {t('menu.exclusive_deals')}
                                </Button>
                              </ListItem>
                              }
                              { ( auth && auth.includes('PROMOTION_CASH_BACK_LIST') ) &&
                              <ListItem className={classes.itemNested} disableGutters >
                                <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                                to={'/pricing_contract'} >
                                  {t('menu.cashback_deals')}
                                </Button>
                              </ListItem>
                              }
                              { ( auth && auth.includes('PROMOTION_CODE_LIST') ) &&
                             <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                               to={'/pricing_promo'} >
                                {t('menu.promotion_package')}
                              </Button>
                             </ListItem>
                             }
                        </List>
                    </Collapse>
                </List>
            }

         { auth.includes('OS_WRITE') &&
           <List {...rest} className={clsx(classes.root, className)}>
               <ListItem className={classes.item} disableGutters onClick={event =>{handleClickAddress();}}>
               {openAddress ? <MenuOpen /> : <MenuClose />}
                 <Button activeClassName={classes.active} className={classes.button} >
                   <div className={classes.icon}>{<ContactPhone />}</div>
                   {t('menu.contacts')}
                 </Button>
               </ListItem>
               <Collapse in={openAddress} timeout="auto" unmountOnExit>
                   <List component="div" disablePadding>

                       { auth.includes('ADDRESS_BOOK_READ') &&
                       <ListItem className={classes.itemNested} disableGutters >
                         <Button activeClassName={classes.active}
                         className={classes.button}
                         component={CustomRouterLink}
                         to={'/address_book/os'} >
                           {auth.includes('EMPLOYEE_WRITE')? t('menu.merchant_contacts') : t('menu.my_contacts') }
                         </Button>
                       </ListItem>
                       }

                       { auth.includes('ADDRESS_BOOK_READ') &&
                       <ListItem className={classes.itemNested} disableGutters >
                         <Button activeClassName={classes.active}
                         className={classes.button}
                         component={CustomRouterLink}
                         to={'/address_book/customer'} >
                             {t('menu.recipient_contacts')}
                         </Button>
                       </ListItem>
                       }
                   </List>
               </Collapse>
           </List>
          }


        { ( auth.includes('SETTING_LOCAL_UPDATE') || auth.includes('SETTING_GLOBAL_UPDATE') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
                <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSettings();}}>
                {openSettings ? <MenuOpen /> : <MenuClose />}
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<SettingsIcon />}</div>
                    {t('menu.settings')}
                  </Button>
                </ListItem>
                <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        { auth.includes('SETTING_GLOBAL_READ') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_systems'} >
                            {t('menu.system_settings')}
                          </Button>
                        </ListItem>
                        }
                        { auth.includes('SETTING_GLOBAL_READ') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_poi_list/gate'} >
                            {t('menu.highway_gate_list')}
                          </Button>
                        </ListItem>
                        }
                        { auth.includes('SETTING_GLOBAL_READ') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_poi_list/office'} >
                            {t('menu.post_office_list')}
                          </Button>
                        </ListItem>
                        }
                        { auth.includes('SETTING_GLOBAL_READ') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_poi_list/poi'} >
                            {t('menu.places_of_interest')}
                          </Button>
                        </ListItem>
                        }
                        { auth.includes('SETTING_GLOBAL_READ') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_towns'} >
                            {t('menu.town_list')}
                          </Button>
                        </ListItem>
                        }
                    </List>
                </Collapse>
            </List>
        }

        { ( auth && auth.includes('POS_SERVICE_ENABLE') ) &&
         <List {...rest} className={clsx(classes.root, className)}>
             <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPosPurchase();}}>
               {openPosPurchase ? <MenuOpen /> : <MenuClose />}
               <Button activeClassName={classes.active} className={classes.button} >
                 <div className={classes.icon}>{<InventoryIcon />}</div>
                 {'Inventory'}
               </Button>
             </ListItem>

             <Collapse in={openPosPurchase} timeout="auto" unmountOnExit>
                 <List component="div" disablePadding>
                         <ListItem className={classes.itemNested} disableGutters >
                           <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                           to={'/pospurchase_supplierlist'} >
                             {'Supplier'}
                           </Button>
                         </ListItem>
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/pospurchase_categorylist'} >
                            {'Product category'}
                          </Button>
                        </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                           <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                           to={'/pospurchase_productlist'} >
                             {'Product'}
                           </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/pospurchase_purchase_order'} >
                               {'Create purchase order'}
                             </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/pospurchase_daily_purchase/Daily'} >
                               {'Daily purchase report'}
                             </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pospurchase_purchaseorder_list'} >
                                {'Purchase report'}
                              </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/pospurchase_daily_item'} >
                               {'Daily purchase item report'}
                             </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pospurchase_purchaseitem_list'} >
                                {'Purchase item report'}
                              </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pospurchase_stockqty_list'} >
                                {'Stock quantity log report'}
                              </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pospurchase_price_change_logs'} >
                                {'Price change log report'}
                              </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pospurchase_currency'} >
                                {'Currency'}
                              </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pospurchase_damage_list'} >
                                {'Damage list'}
                              </Button>
                         </ListItem>
                         {/*
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/posreport_report_summary'} >
                                {t('Profit summary')}
                              </Button>
                         </ListItem>
                        */}
                 </List>
             </Collapse>
         </List>
         }

        { ( auth && auth.includes('POS_SERVICE_ENABLE') ) &&
         <List {...rest} className={clsx(classes.root, className)}>
             <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPosSale();}}>
               {openPosOrder ? <MenuOpen /> : <MenuClose />}
               <Button activeClassName={classes.active} className={classes.button} >
                 <div className={classes.icon}>{<PointOfSaleIcon />}</div>
                 {'Point of Sale'}
               </Button>
             </ListItem>

             <Collapse in={openPosOrder} timeout="auto" unmountOnExit>
                 <List component="div" disablePadding>

                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/possale_sale_order'} >
                               {'Create sales order'}
                             </Button>
                         </ListItem>

                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/possale_daily_sales_list/Daily'} >
                               {'Daily sales report'}
                             </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/possale_sale_order_list/Active'} >
                               {t('Sales report')}
                             </Button>
                         </ListItem>
                         {/*<ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/possale_daily_item'} >
                               {t('Daily sales item report')}
                             </Button>
                         </ListItem>*/}
                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/possale_order_saleitem'} >
                               {t('Sales item report')}
                             </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                               <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                               to={'/possale_sale_order_cancel_list/Canceled'} >
                                 {t('Sales canceled report')}
                               </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                               <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                               to={'/possale_merchant_business_list'} >
                                 {t('Page and sale person')}
                               </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                               <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                               to={'/possale_sale_order_seller_biz_list'} >
                                 {t('Sale Report by Person & Page')}
                               </Button>
                         </ListItem>
                        <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/possale_customer_list'} >
                                {t('Customer list')}
                              </Button>
                        </ListItem>
                        { auth.includes('ACCOUNTING_LEDGER_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/possale_winning_customer_list'} >
                            {'Winner list'}
                          </Button>
                        </ListItem>
                        }
                 </List>
             </Collapse>
         </List>
         }

         {/*{ ( auth && auth.includes('POS_LIST') && posPrivilege && posPrivilege.value==='true' && userData && userData.roleName ==='OS') &&
         <List {...rest} className={clsx(classes.root, className)}>
             <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPosPurchase();}}>
               {openPosPurchase ? <MenuOpen /> : <MenuClose />}
               <Button activeClassName={classes.active} className={classes.button} >
                 <div className={classes.icon}>{<InventoryIcon />}</div>
                 {'Inventory'}
               </Button>
             </ListItem>

             <Collapse in={openPosPurchase} timeout="auto" unmountOnExit>
                 <List component="div" disablePadding>
                         <ListItem className={classes.itemNested} disableGutters >
                           <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                           to={'/pospurchase_productlist'} >
                             {'Product'}
                           </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/pospurchase_daily_purchase'} >
                               {'Daily purchase report'}
                             </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pospurchase_purchaseorder_list'} >
                                {'Purchase report'}
                              </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/pospurchase_daily_item'} >
                               {'Daily purchase item report'}
                             </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pospurchase_purchaseitem_list'} >
                                {'Purchase item report'}
                              </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pospurchase_stockqty_list'} >
                                {'Stock quantity log report'}
                              </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/pospurchase_price_change_logs'} >
                                {'Price change log report'}
                              </Button>
                         </ListItem>
                 </List>
             </Collapse>
         </List>
         }*/}

        { ( auth && auth.includes('POS_LIST') && posPrivilege && posPrivilege.value==='true' && userData && userData.roleName ==='OS') &&
         <List {...rest} className={clsx(classes.root, className)}>
             <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPosSale();}}>
               {openPosOrder ? <MenuOpen /> : <MenuClose />}
               <Button activeClassName={classes.active} className={classes.button} >
                 <div className={classes.icon}>{<PointOfSaleIcon />}</div>
                 {'Point of Sale'}
               </Button>
             </ListItem>

             <Collapse in={openPosOrder} timeout="auto" unmountOnExit>
                 <List component="div" disablePadding>

                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/possale_sale_order'} >
                               {'Create sales order'}
                             </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/possale_daily_sales_list/Daily'} >
                               {'Daily sales report'}
                             </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/possale_sale_order_list/Active'} >
                               {t('Sales report')}
                             </Button>
                         </ListItem>
                         {/*<ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/possale_daily_item'} >
                               {t('Daily sales item report')}
                             </Button>
                         </ListItem>*/}
                         <ListItem className={classes.itemNested} disableGutters >
                             <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                             to={'/possale_order_saleitem'} >
                               {t('Sales item report')}
                             </Button>
                         </ListItem>
                         <ListItem className={classes.itemNested} disableGutters >
                               <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                               to={'/possale_sale_order_cancel_list/Canceled'} >
                                 {t('Sales canceled report')}
                               </Button>
                         </ListItem>
                        <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/possale_customer_list'} >
                                {t('Customer list')}
                              </Button>
                        </ListItem>
                 </List>
             </Collapse>
         </List>
         }

    { ( auth && auth.includes('LOGS_READ') ) &&
    <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.item} disableGutters >
            <Link component="a" href="/audit_logs" >
            <Button
                activeClassName={classes.active}
                className={classes.button} >
              <div className={classes.icon}>{<EventNoteIcon />}</div>
                {t('menu.audit_logs')}
            </Button>
            </Link>
        </ListItem>
    </List>
    }

    <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.item} disableGutters >
            <Link
                        component="a"
                        href="/toc.html"
                        target="_blank">
            <Button
                activeClassName={classes.active}
                className={classes.button} >
              <div className={classes.icon}>{<VerifiedUser />}</div>
                {t('menu.terms_conditions')}
            </Button>
            </Link>
        </ListItem>
    </List>

    <List {...rest} className={clsx(classes.root, className)}>
        <ListItem className={classes.item} disableGutters >
            <Button
                activeClassName={classes.active}
                className={classes.button}
                onClick={event =>{dataService.removeTokenCookie();}}
            >
          <div className={classes.icon}>{<LogoutIcon />}</div>
            {t('menu.logout')}
          </Button>
        </ListItem>
    </List>

    </div>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

//export default SidebarNav;
export default (props) => {
    const { t, i18n } = useTranslation();
    return (<SidebarNav lang={t} i18n={i18n} props{... props} />)
}